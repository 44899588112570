import type { AppBarProps } from "@mui/material";
import {
  AppBar,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import MoreMenuItem from "./MoreMenuItem";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import PopupState, { bindHover, bindMenu } from "material-ui-popup-state";
import type { ExpandedOption } from "~/schemas";
import DtwImage from "./DtwImage";
import { useMainData, useSettingsData } from "~/hooks";
import Cart from "./Cart";
import { Search } from "./Search";
import { Link as RemixLink } from "@remix-run/react";
import { ProfileOptions } from "./ProfileOptions";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

const drawerWidth = 240;

/**
 * The main navigation bar, that displays its options in the bar on desktop and hides them in a drawer on mobile.
 */
export default function NavBar(props: AppBarProps) {
  const main = useMainData();
  const { template } = useSettingsData();
  const {
    navbar: { options },
    logo,
  } = main;

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  /**
   * Renders a recursive list of ListItems that expand on click.
   * @param item The item to display
   * @param index The index of the item in the parent array, used for the key
   * @returns A PopupState element that controls the open state of the item
   */
  function renderDrawerExpandableItems(item: ExpandedOption, index: number) {
    return (
      <PopupState
        key={`drawer-expanded-${index}`}
        popupId={`drawer-expanded-${index}`}
        variant="popper"
      >
        {(popupState) => (
          <React.Fragment key={`drawer-expanded-${index}`}>
            {!!item.options && (
              <ListItemButton onClick={popupState.toggle}>
                <ListItemIcon>
                  {popupState.isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            )}
            {!item.options && item.href && (
              <ListItem>
                <RemixLink to={item.href}>
                  <ListItemText primary={item.text} />
                </RemixLink>
              </ListItem>
            )}

            {!!item.options && (
              <Collapse in={popupState.isOpen} unmountOnExit>
                <List component="div" disablePadding>
                  <Divider />
                  {item.options.map(renderDrawerExpandableItems)}
                  <Divider />
                </List>
              </Collapse>
            )}
          </React.Fragment>
        )}
      </PopupState>
    );
  }

  /**
   * Renders a recursive list of items that expands on hover.
   * @param item The item to display
   * @param index The index in the parent array to use for the key
   * @returns A MoreMenuItem instance if the item has suboptions or a MenuItem otherwise.
   */
  function renderExpandableItems(item: ExpandedOption, index: number) {
    if (item.options) {
      return (
        <MoreMenuItem key={`expand-${item.text}`} label={item.text}>
          {item.options.map((o) => renderExpandableItems(o, index * 100))}
        </MoreMenuItem>
      );
    }
    if (item.href) {
      return (
        <RemixLink to={item.href} key={`final-${item.href}`}>
          <MenuItem>{item.text}</MenuItem>
        </RemixLink>
      );
    }
    return <></>;
  }

  function renderIcon(
    item: { muiIcon: string; text: string; href: string },
    index: number,
    listItem = false
  ) {
    if (item.href === "/cart") {
      return <Cart key={`icon-${index}`} listItem={listItem} />;
    }
    if (item.href === "/search") {
      return <Search key={`icon-${index}`} listItem={listItem} />;
    }
    if (item.href === "/profile") {
      return <ProfileOptions key={`icon-${index}`} listItem={listItem} />;
    }
    return listItem ? (
      <RemixLink
        key={`icon-${index}`}
        to={item.href}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        {" "}
        <ListItem>
          <ListItemIcon>
            <img
              src={`/assets/${template}/${item.muiIcon}`}
              alt={item.text}
            ></img>
          </ListItemIcon>
          <ListItemText>{item.text}</ListItemText>
        </ListItem>
      </RemixLink>
    ) : (
      <IconButton href={item.href} key={`icon-${index}`}>
        <img src={`/assets/${template}/${item.muiIcon}`} alt={item.text}></img>
      </IconButton>
    );
  }

  const drawer = (
    <Box>
      <List>
        {options.icons.map((item, index) => renderIcon(item, index, true))}
        <Divider />
        {options.expanded.map(renderDrawerExpandableItems)}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "#ffffff", top: 0, display: "flex" }}
        {...props}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <img src={`/assets/${template}/menu.svg`} alt="Menu button" />
          </IconButton>
          <Box
            sx={{
              width: {
                xs: "60%",
                md: "20%",
              },
            }}
          >
            <RemixLink style={{ display: "flex" }} to="/">
              <Box
                sx={{
                  width: 1,
                  maxHeight: {
                    xs: 40,
                    md: 64,
                  },
                  margin: 1,
                  maxWidth: 1,
                  display: "flex",
                }}
              >
                <DtwImage
                  style={{ maxHeight: "100%", maxWidth: "100%" }}
                  objectFit="contain"
                  picture={logo}
                  alt="Main logo"
                />
              </Box>
            </RemixLink>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              width: "60%",
              justifyContent: "center",
            }}
          >
            {options.expanded.map((option, index) => (
              <Box
                key={`expanded-${index}`}
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <PopupState
                  variant="popover"
                  popupId={`expanded-${index}-menu`}
                >
                  {(popupState) => {
                    const link = (
                      <Link
                        component="span"
                        id={`expanded-${index}-btn`}
                        {...bindHover(popupState)}
                        underline="none"
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        {option.text}
                      </Link>
                    );
                    return (
                      <div>
                        {option.options && !option.href ? (
                          link
                        ) : (
                          <RemixLink to={option.href!}>{link}</RemixLink>
                        )}
                        {!!option.options && (
                          <HoverMenu
                            {...bindMenu(popupState)}
                            id={`expanded-${index}-menu`}
                            MenuListProps={{
                              "aria-labelledby": `expanded-${index}-btn`,
                            }}
                          >
                            {option.options.map(renderExpandableItems)}
                          </HoverMenu>
                        )}
                      </div>
                    );
                  }}
                </PopupState>
              </Box>
            ))}
          </Box>
          <Box sx={{ width: "20%", justifyContent: "end", display: "flex" }}>
            {options.icons.map((e, i) => (
              <Box
                sx={{
                  display: {
                    xs: e.alwaysVisibleOnMobile ? "unset" : "none",
                    md: "unset",
                  },
                }}
                key={`icon-${i}`}
              >
                {renderIcon(e, i, false)}
              </Box>
            ))}
          </Box>
          sx
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}
